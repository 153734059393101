import React from 'react';
import { useStaticQuery, graphql} from 'gatsby';
import {
    Box,
    Flex,
} from '@chakra-ui/react';
import NewsCard from './NewsCard';
import { getImage } from 'gatsby-plugin-image';

const NewsCardRack = () => {
    const data = useStaticQuery(graphql`
    {
      allPrismicNews(
        limit: 3
        sort: {fields: data___date_posted, order: DESC}
        filter: {data: {date_posted: {ne: null}}, tags: {eq: "POOP"}}
      ) {
        edges {
          node {
            uid
            url
            data {
              post_title {
                text
              }
              post_excerpt {
                text
              }
              featured_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      aspectRatio: 1.5
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                      transformOptions: {cropFocus: CENTER}
                    )
                  }
                }
              }
              date_posted(formatString: "MMMM D, YYYY")
            }
            tags
          }
        }
      }
    }
  `)

  const document = data.allPrismicNews.edges;
      return (
        <Box mb="24">
            <Flex w="100%" flexWrap="wrap" justifyContent="flex-start">
              {document.map((node, index) => {
                  return (
                      <NewsCard
                          image={getImage(node.node.data.featured_image.localFile)}
                          key={index}
                          title={node.node.data.post_title.text}
                          excerpt={node.node.data.post_excerpt.text}
                          url={node.node.url}
                          tags={node.node.tags}
                      />
                  )
              })}
            </Flex>
        </Box>
    );
}

export default NewsCardRack;